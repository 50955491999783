.products {
  width: 100%;
}

.products-mobile {
  display: none;
}

.buttonB {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: 600;
  width: 322px;
  height: 68px;
  text-decoration: none;
  border: 3px solid #1EF97C;
}

.retangles {
  margin: 80px 0;
}

.products h1 {
  width: 25%;
  margin-left: 350px;
  margin-bottom: 55px;
  font-size: 20px;
}

.product-items {
  display: flex;
  justify-content: center;
}

.product-item {
  width: 15%;
  margin: 0 60px;
}

.product-item img {
  margin-bottom: 50px;
  width: 200px;
}

.product-item p {
  height:195px;
  font-size: 13px;
}

.product-item a {
  margin-left: -3px;
  width: 250px !important;
  font-size: 15px;
}



@media screen and (max-width: 1400px) {
  .retangles {
    margin: 80px 0;
    width: 180px;
  }
  
  .products h1 {
    width: 30%;
    margin-left: 220px;
    font-size: 21px;
  }
  
  .product-items {
    display: flex;
    justify-content: center;
  }
  
  .product-item {
    width: 15%;
    margin: 0 60px;
  }
  
  .product-item img {
    margin-bottom: 30px;
    width: 200px;
  }
  
  .product-item p {
    height:195px;
    font-size: 13px;
  }
  
  .product-item a {
    margin-left: -3px;
    width: 250px !important;
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .products {
    display: none;
  }

  .products-mobile {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .products-mobile h1 {
    text-align: center;
    width: 80%;
    margin: auto;
    font-size: 30px;
  }

  .products-mobile ul {
    margin: auto;
    width: 90%;
    list-style: none;
  }

  .products-mobile li {
    display: flex;
    margin: 30px 0;
  }

  .products-mobile li p {
    font-weight: bold;
    margin: 30px 0;
  }

  .products-mobile li div {
    width: 60%;
  }

  .products-mobile img {
    width: 30%;
    padding-right: 20px;
    height: 150px;
  }

  .products-mobile a {
    font-size: 16px;
    text-align: center;
  }


}