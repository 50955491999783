.intro-home-page h1 {
  text-align: center;
  font-size: 50px;
  margin-bottom: 70px;
}

.intro-home-page-mobile {
  display: none;
}

.intro-home-page-content img {
  width: 500px;
}

.intro-home-page-content {
  display: flex;
  margin-left: 120px;
}

.intro-home-page-content div {
  margin-left: 100px;
  width: 22%;
  padding-top: 50px;
}

.intro-home-page-content h2 {
  margin-bottom: 30px;
  font-size: 20px;
}

.intro-home-page-content p {
  font-size: 13px;
}

@media screen and (max-width: 1440px) {
  .intro-home-page h1 {
    font-size: 40px;
    margin-bottom: 70px;
  }

  .intro-home-page-content {
    display: flex;
    margin-left: 150px;
  }

  .intro-home-page-content img {
    width: 500px;
  }
  
  .intro-home-page-content div {
    margin-left: 100px;
    width: 22%;
    padding-top: 70px;
  }
  
  .intro-home-page-content h2 {
    margin-bottom: 30px;
    font-size: 18px;
  }
  
  .intro-home-page-content p {
    font-size: 13px;
  }
  
}

@media screen and (max-width: 600px) {
  .intro-home-page {
    display: none;
  }

  .intro-home-page-mobile {
    display: initial;
    width: 90%;
  }

  .intro-home-page-mobile h1 {
    width: 80%;
    margin: 0 auto 50px auto;
    text-align: center;
    font-size: 30px;
  }
  
  green {
    color: #1EF97C;
  }

  .intro-home-page-mobile-content img {
    width: 70%;
  }

  .intro-home-page-mobile-content h2 {
    width: 80%;
    margin: 50px auto;
    text-align: center;
    font-size: 28px;
  }

  .intro-home-page-mobile-content p {
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 18px;
  }

}
