.maquininha {
  margin-top: 130px;
  display: flex;
  margin-left: 250px;
}

.maquininha-descpription {
  margin-left: 100px;
  margin-top: 20px;
}

.maquininha img {
  width: 650px;
}

.maquininha-descpription h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 36px;
}

.maquininha-descpription ul {
  list-style-image: url("../../../../images/icons/item-description-benefits.svg");
  width: 50%;
  margin-left: 20px;
}

.maquininha-descpription h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.maquininha-descpription p {
  font-size: 13px;
  margin-bottom: 25px;
}

.maquininha-descpription a {
  margin-top: 30px;
  margin-left: -20px;
}

.maquininha-mobile {
  display: none;
}

@media screen and (max-width: 1400px) {
  .maquininha {
    margin-top: 150px;
    margin-left: 150px;
  }

  .maquininha img {
    width: 600px;
  }

  .maquininha-descpription {
    margin-left: 120px;
    margin-top: 20px;
  }

  .maquininha-descpription h1 {
    font-size: 21px;
    margin-bottom: 25px;
  }

  .maquininha-descpription h3 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .maquininha-descpription p {
    font-size: 12px;
    margin-bottom: 20px;
  }
  
  .maquininha-descpription a {
    width: 286px;
    height: 56px;
    font-size: 15px;
  }
}


@media screen and (max-width: 600px) {
  .maquininha {
    display: none;
  }

  .maquininha-mobile {
    display: initial;
  }

  .maquininha-mobile img {
    width: 90%;
  }

  .maquininha-mobile h1 {
    width: 90%;
    font-size: 35px;
    margin: 30px auto;
    text-align: center;
  }

  .maquininha-mobile ul {
    width: 80%;
    margin: auto;
    list-style-image: url("../../../../images/icons/item-description-benefits.svg");
  }

  .maquininha-mobile ul h2 {
    font-size: 25px;
    margin-left: 10px;
  }

  .maquininha-mobile div {
    margin: 30px 0;
  }
  .maquininha-mobile li p {
    font-size: 20px;
    margin-left: 10px;
    margin-top: 15px;
  }
}
