.header {
  padding: 39px 50px;
  width: 100%;
}

.off {
  display: none;
}

.icon-menu {
  display: none;
}

.logo {
  width: 100px;
  height: 40px  
}

.logo img {
  width: 100px
}

.options-header {
  margin-left: 13%;
}

.options-header ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.options-header ul li {
  font-size: 17px;
  font-weight: 400;
  margin-right: 112px;
  text-transform: uppercase;
}

.options-header a {
  text-decoration: none;
  color: #000000;
}

@media screen and (max-width: 1400px) {
  
  .logo {
    width: 80px;
  }
  
  .options-header {
    margin-left: 15%;
  }
  
  .options-header ul li {
    font-size: 14px;
  }

}

@media screen and (max-width: 600px) {

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  
  .icon-menu {
    display: initial;
    cursor: pointer;
  }

  .active {
    display: initial;
  }
  
  #menu-mobile {
    padding: 40px 20px;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #818181;
    z-index: 100;
  }

  #menu-mobile ul {
    list-style: none;
  }

  #menu-mobile .icon-menu {
    position: absolute;
    right: 50px;
    top: 40px;
    color: #1EF97C;
  }

  #menu-mobile ul {
    margin-top: 30px;
  }

  #menu-mobile a {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    font-size: 30px;
  }

  .line {
    margin: 10px 0;
    width: 90%;
    height: 2px;
    background-color: #1EF97C;
  }

  .options-header {
    display: none;
  }
}