blue {
  color: #009FFD;
  font-weight: bold;
}

green {
  color: #1EF97C;
  font-weight: bold;
}

.ica-indique-ganhe {
  width: 90%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
}

.ica-indique-ganhe h1 {
  text-align: center;
  font-size: 50px;
  margin-bottom: 50px;
}

.ica-indique-section-1 {
  display: flex;
}

.ica-indique-section-1 img {
  width: 40%;
}

.ica-indique-section-1 p {
  font-size: 30px;
  width: 40%;
  margin-left: 10%;
  margin-top: 80px;
}

.ica-indique-section-2 {
  display: flex;
  justify-content: end;
}
.ica-indique-section-2 img {
  width: 45%;
}
.ica-indique-section-2 p{
  font-size: 30px;
  margin-left: 30%;
  margin-top: 20%;
  width: 65%;
}

.ica-indique-section-2-mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .ica-indique-section-2-mobile {
    display: flex;
    flex-direction: column;
  }

  .ica-indique-section-2 {
    display: none;
  }

  .ica-indique-section-1 {
    flex-direction: column;
    text-align: center;
  }

  .ica-indique-section-1 img {
    width: 70%;
    margin: auto;
  }

  .ica-indique-section-1 p {
    width: 90%;
    margin: 40px auto;
    font-size: 20px;
    text-align: center;
  }

  .ica-indique-section-2-mobile img {
    width: 80%;
    margin: 20px auto 60px auto;
  }

  .ica-indique-section-2-mobile p {
    width: 90%;
    text-align: center;
    margin: auto;
    font-size: 20px;
    margin-bottom: 20px;
  }
}