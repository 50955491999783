.completao {
  margin-top: 160px;
  margin-left: 150px;
  display: flex;
  position: relative;
}

.completed-image {
  margin-top: 30px;
  position: relative;
  left: 50px;
  width: 500px;
}

.description-complete {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
}

.description-complete h1 {
  font-size: 25px;
  font-weight: 900px;
  margin-bottom: 20px;
}

.descriptions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.description-item {
  width: 240px;
  height: 240px;
  background-color: #EDEDED;
  margin: 17.5px 40px;
  padding: 28px 22px;
}

.description-item h1 {
  margin: 10px 0;
  font-size: 16px;
}

.description-item p {
  font-size: 13px;
}

.description-item img {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 1440px) {
  .completao {
    margin-top: 180px;
    margin-left: 80px;
  }

  .completed-image {
    margin-top: 30px;
    width: 400px;
  }
  
  .description-complete {
    margin-left: 15%;
  }

  .descriptions {
    margin-top: 0px;
  }

  .description-item {
    width: 210px;
    height:210px;
    margin: 15px 20px;
    padding: 15px 15px;
  }

  .description-item h1 {
    font-size: 16px;
  }
  
  .description-item p {
    font-size: 12px;
  }

  .description-item img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 600px) {
  .completao {
    width:  100%;
    margin-top: 160px;
    margin-left: 0px;
    display: initial;
  }
  
  .completed-image {
    display: none;
  }
  
  .description-complete {
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    width: 90%;
    margin: auto;
  }
  
  .description-complete h1 {
    font-size: 30px;
    width: 80%;
    margin: 0 auto 20px auto;
    text-align: center;
    font-weight: 900px;
  }
  
  .descriptions {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  green {
    color: #1EF97C;
  }
  
  .description-item {
    width: 90%;
    height: 240px;
    background-color: #EDEDED;
    margin: 20px;
    padding: 20px;
  }
  
  .description-item h2 {
    margin: 10px 0;
    font-size: 20px;
  }
  
  .description-item p {
    font-size: 18px;
  }
  
  .description-item img {
    width: 30px;
    height: 30px;
  }
}