.sac {
  display: flex;
  margin-top: 80px;
  margin-left: 300px;
  margin-bottom: 60px;
}

.sac h1 {
  width: 50%;
  margin-left:290px;
  margin-bottom: 40px;
  font-size: 25px;
}

.sac-information {
  margin-top: 175px;
  font-size: 16px;
  margin-left: 50px;
}

.sac-image img {
  width: 500px;
}

.information-title {
  width: 45%;
  margin-bottom: 20px;
  font-size: 16px;
}

.sac-information p {
  margin-bottom: 20px;
}

.ultimo-numero {
  margin-left: 340px;
}

.sac-mobile {
  display: none;
}

@media screen and (max-width: 1400px) {
  .sac {
    margin-left: 150px;
  }
  
  .sac h1 {
    font-size: 25px;
  }

  .sac-image img {
    width: 500px;
  }
  
  .sac-information {
    font-size: 13px;
    margin-left: 50px;
  }
  
  .information-title {
    width: 45%;
    margin-bottom: 25px;
  }
  
  .sac-information p {
    margin-bottom: 20px;
  }
  
  .ultimo-numero {
    margin-left: 280px;
  }
}

@media screen and (max-width: 600px) {
  .sac {
    display: none;
  }

  .sac-mobile {
    display: initial;
  }

  .sac-mobile div {
    width: 80%;
    margin: 40px auto;
    text-align: center;
  }


  .sac-mobile h1 {
    width: 90%;
    margin: 40px 0;
    font-size: 30px;
  }

  .sac-mobile img {
    width: 80%;
  }

}