.ica-card {
  width: 90%;
  margin: 40px auto 80px auto;
}

.ica-card-partners-mobile {
  display: none;
}

.ica-card-title {
  text-align: center;
  font-size: 30px;
}

.ica-card-title b {
  color: #009FFD;
}

.section-benefits-ica-card {
  display: flex;
  margin: 50px auto 0 auto;
  justify-content: space-between;
}

.ica-card-description {
  width: 25%;
  margin-top: 20px;
}

.ica-card-description h1 {
  font-size: 25px;
  margin-bottom: 15px;
}

.ica-card-description p {
  width: 65%;
  font-size: 18px;
  font-weight: 500;
}

.ica-card-items {
  width: 75%;
}

.ica-card-items h2 {
  color: #009FFD;
  font-size: 35px;
  width: 400px;
  margin-left: 50px;
}

.ica-card-list-items ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.ica-card-list-items li {
  width: 250px;
  height: 250px;
  margin: 20px 40px;
  padding: 20px 15px;
  background-image: url('../../images/icons/Back-ica-card.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.ica-card-list-items li h3 {
  font-size: 16px;
  margin-top: 15px;
}
.ica-card-list-items li p {
  font-size: 13px;
  margin-top: 5px;
}

.ica-card-beneficard {
  display: flex;
  margin: 50px auto 50px auto;
}

.ica-card-beneficard-image {
  width: 35%;
  margin-right: 50px;
}

.ica-card-beneficard-description h1 {
  text-transform: uppercase;
  font-size: 50px;
  color: #009FFD;
}

.ica-card-beneficard-description ul {
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
}

.ica-card-beneficard-description ul div {
  width: 40%;
  margin-right: 20px;
}

.ica-card-beneficard-description ul li {
  width: 80%;
  font-size: 15px;
  margin-top: 10px;
}

.ica-card-partners h1 {
  text-align: center;
  text-transform: uppercase;
  color: #009FFD;
  font-size: 40px;
}

.ica-card-partners-list {
  display: flex;
  margin-top: 30px;
  width: 100%;
}

.ica-card-partners-list ul {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  list-style: none;
}

.ica-card-partners-list li {
  margin: 10px 15px;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ica-card-partners-list img {
  width: 180px;
}


@media screen and (max-width: 1400px) {
  .ica-card-list-items li {
    width: 200px;
    height: 200px;
  }

  .ica-card-list-items img {
    width: 40px;
  }

  .ica-card-list-items li h3 {
    font-size: 14px;
    margin-top: 5px;
  }
  .ica-card-list-items li p {
    font-size: 12px;
  }

  .ica-card-description p {
    font-size: 16px;
    font-weight: 500;
  }
}

@media screen and (max-width: 600px) {
  .ica-card-partners {
    display: none;
  }

  .ica-card-partners-mobile {
    display: flex;
    flex-direction: column;
  }
  
  .ica-card {
    width: 100%;
    margin: 40px auto 80px auto;
  }

  .ica-card-title h1 {
    font-size: 30px;
    width: 90%;
    margin: auto;
  }

  .section-benefits-ica-card {
    width: 90%;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
  }

  .ica-card-description {
    width: 90%;
    margin: 20px auto;
  }
  
  .ica-card-description h1 {
    font-size: 25px;
    width: 70%;
    margin-bottom: 40px;
  }
  
  .ica-card-description p {
    width: 100%;
    font-size: 18px;
  }

  .ica-card-items {
    width: 90%;
    margin: auto;
  }
  .ica-card-items h2 {
    font-size: 25px;
    margin: 40px 0 20px 0;
    width: 80%;
    text-align: center;
    margin: auto;
  }

  .ica-card-list-items {
    margin: auto;
  }

  .ica-card-list-items ul {
    flex-direction: column;
    justify-content: center;
  }

  .ica-card-list-items li {
    margin: 30px auto;
    width: 80%;
    height: 300px;
    background-position: right;
  }
  .ica-card-list-items img {
    width: 20%;
  }
  
  .ica-card-list-items li h3 {
    font-size: 20px;
    margin-top: 10px;
  }
  .ica-card-list-items li p {
    font-size: 16px;
    margin-top: 5px;
  }

  .ica-card-beneficard {
    flex-direction: column;
  }

  .ica-card-beneficard img {
    width: 70%;
  }

  .ica-card-beneficard-description h1 {
    font-size: 30px;
    margin-left: 30px;
    margin-top: 40px;
  }

  .ica-card-beneficard-description h2 {
    font-size: 30px;
    width: 50%;
    margin-top: 15px;
    margin-bottom: 40px;
    margin-left: 30px;
  }

  .ica-card-beneficard-description ul {
    flex-direction: column;
    background-color: #323232;
    width: 100%;
    margin-left: 0;
    padding: 5% 10%;
  }

  .ica-card-beneficard-description ul li {
    background-color: #323232;
    color: white;
    font-size: 18px;
    width: 100%;
    margin: 20px 0;
  }

  .ica-card-partners-mobile h1 {
    font-size: 30px;
    text-transform: uppercase;
    color: #009FFD;
    text-align: center;
  }

  .ica-card-beneficard-description ul div {
    width: 100%;
  }

  .ica-card-partners-list ul {
    flex-direction: column;
    margin: auto;
  }


  .ica-card-partners-list li {
    margin: 40px auto;
  }

}