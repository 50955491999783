.pre-cadastro {
  display: flex;
  width: 90%;
  margin: 30px auto 50px auto;
  justify-content: space-around;
}

.form-pre-cadastro h1 {
  font-size: 25px;
  margin-bottom: 15px;
}

.pre-cadastro-image {
  width: 50%;
  margin-right: 15%;
}

.download-qrCode {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.download-qrCode img {
  width: 150px;
}

.download-qrCode-mobile {
  display: none;
}

@media screen and (max-width: 1440px) {
  .pre-cadastro {
    width: 80%;
  }
  .pre-cadastro {
    margin: 30px auto 50px auto;
  }
  
}

@media screen and (max-width: 600px) {

 .download-qrCode {
   display: none;
 }

 .download-qrCode-mobile {
  display: flex;
  flex-direction: column;
 }

 .pre-cadastro {
   flex-direction: column;
   text-align: center;
 }

 .pre-cadastro-image {
  width: 90%;
  margin: 0 auto 50px auto;
  }

  .form-pre-cadastro h1 {
    width: 80%;
    font-size: 35px;
    margin: 0 auto 20px auto;
  }

  .form-pre-cadastro p {
    font-size: 20px;
  }

.download-qrCode-mobile a {
  margin-top: 40px;
}

  .download-qrCode-mobile img {
    width: 60%;
  }

}