.error-404 {
  display: flex;
  align-items: center;
}

.error-404-mobile {
  display: none;
}

.error-404 div {
  margin-top: -100px;
}

.error-404 h1 {
  font-size: 160px;
  margin-bottom: 20px;
  color: #323232;
}

.error-404 h2 {
  font-size: 60px;
  margin-bottom: 15px;
  color: #323232;
}

.error-404 p {
  font-size: 30px;
  color: #383838;
}

@media screen and (max-width: 1400px) {
  .error-404 h1 {
    font-size: 80px;
    margin-bottom: 20px;
    color: #323232;
  }

  .error-404 img {
    width: 700px;
  }
  
  .error-404 h2 {
    font-size: 40px;
    margin-bottom: 15px;
    color: #323232;
  }
  
  .error-404 p {
    font-size: 20px;
    color: #383838;
  }
}

@media screen and (max-width: 600px) {

  .error-404 {
    display: none;
  }

  .error-404-mobile {
    display: block;
    width: 90%;
    margin: 100px auto 0 auto;
  }

  .error-404-mobile img {
    width: 100%;
  }


  .error-404-mobile h1 {
    font-size: 80px;
    margin-bottom: 20px;
    color: #323232;
  }
  
  .error-404-mobile h2 {
    font-size: 45px;
    width: 80%;
    margin-bottom: 15px;
    color: #323232;
  }
  
  .error-404-mobile p {
    font-size: 20px;
    color: #383838;
  }
}