.protection-pix {
  width: 80%;
  margin: 60px auto 0 auto;
}

.protection-pix-mobile {
  display: none;
}

.containt-protection-pix {
  display: flex;
}

.card-1 {
  width: 30%;
  margin-top: 120px;
}

.card-1 h1 {
  font-size: 25px;
  margin-bottom: 30px;
}

.card-1 p {
  font-size: 16px;
}

.circle-containt {
  position: relative;
}

.circle-containt img {
  width: 1100px;
}

.cards-circle-containt {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.card-single-circle {
  position: relative;
  left: 390px;
  top: 50px;
  width: 20%;
}

.cards-circle {
  position: relative;
  top: 423px;
  left: 30px;
  display: flex;
  justify-content: space-between;
}

.cards-circle div {
  width: 20%;
  margin-right: 160px;
}

.cards-circle-containt h2 {
  font-size: 21px;
  margin-bottom: 20px;
}

.cards-circle-containt p {
  font-size: 16px;
}

.list-modulos {
  margin: 100px auto 100px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modulos-description {
  width: 30%;
}

.modulos-description div {
  display: flex;
  margin-bottom: 20px;
}

.modulos-description div img {
  margin-top: 5px;
  margin-right: 10px;
}

.modulos-description p {
  margin-left: 28px;
  font-size: 15px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1400px) {
  .protection-pix {
    width: 90%;
    margin: 60px auto 0 auto;
  }

  .card-1 {
    width: 25%;
  }
  
  .card-1 h1 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .card-1 p {
    font-size: 13px;
  }
  
  .circle-containt img {
    width: 850px;
  }
  
  .card-single-circle {
    left: 300px;
    top: 40px;
    width: 20%;
  }
  
  .cards-circle {
    top: 315px;
    left: 22px;
  }
  
  .cards-circle div {
    width: 20%;
    margin-right: 128px;
  }
  
  .cards-circle-containt h2 {
    font-size: 18px;
  }
  
  .cards-circle-containt p {
    font-size: 13px;
  }
  
  .modulos-description div {
    display: flex;
    margin-bottom: 20px;
  }
  
  .modulos-description div img {
    margin-top: 5px;
    margin-right: 10px;
  }

  .modulos-description div h2 {
    font-size: 18px;
  }
  
  .modulos-description p {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .protection-pix {
    display: none;
  }

  .protection-pix-mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }

  .protection-pix-mobile h1 {
    text-align: center;
    font-size: 30px;
  }

  .sub-title {
    text-align: center;
    width: 70%;
    margin: 40px auto;
  }

  .LogoPix {
    margin: 0 auto 30px auto;
    width: 40%;
    height: px;
  }

  .protection-pix-mobile ul {
    list-style: none;
  }
  .protection-pix-mobile ul li {
    margin-left: 5%;
    margin-top: 50px;
  }
  .protection-pix-mobile ul li div {
    display: flex;
    width: 50%;
    margin: 15px 0;
  }

  .protection-pix-mobile ul li div h2 {
    font-size: 20px;
  }
  .protection-pix-mobile ul li div img {
    height: 15px;
    margin-top: 10px;
    margin-right: 15px;
  }

  .protection-pix-mobile ul li p {
    line-height: 25px;
  }
}