.sua-maquininha {
  display: flex;
  width: 70%;
  margin: 30px auto 80px auto;
  justify-content: space-between;
}

.sua-maquininha-mobile {
  display: none;
}

.sua-maquininha-description {
  margin-top: 60px;
}

.sua-maquininha-description h1 {
  margin-bottom: 30px;
  font-size: 25px;
}

.sua-maquininha-description input[type=button] {
  width: 400px;
  height: 50px;
  border: none;
  background-color: #323232;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  margin-top: 50px
}

@media screen and (max-width: 1400px) {
  .sua-maquininha {
    width: 85%;
  }

  .sua-maquininha img {
    margin-top: 50px;
    width: 500px;
    height: 500px;
  }
  
  .sua-maquininha-description h1 {
    margin-bottom: 30px;
    font-size: 20px;
  }
  
  .sua-maquininha-description input[type=button] {
    width: 350px;
  }
}

@media screen and (max-width: 600px) {
  .sua-maquininha {
    display: none;
  }

  .sua-maquininha-mobile {
    display: flex;
    margin: 40px 0 80px 0;
  }

  .container-mobile-forms {
    margin-left: 10%;
  }

  .sua-maquininha-description-mobile h1 {
    width: 70%;
    text-align: center;
    margin: 0 auto 50px auto;
    font-size: 25px;
  }
  
  .sua-maquininha-description-mobile input[type=button] {
    width: 350px;
    height: 50px;
    border: none;
    background-color: #323232;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    margin-top: 50px
  }
  
  .sua-maquininha-description-mobile img {
    width: 100%;
    margin-bottom: 20px;
  }
}