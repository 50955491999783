.produtos-ica {
  width: 90%;
  margin: auto;
}

.buttonB {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: 600;
  width: 322px;
  height: 68px;
  text-decoration: none;
  border: 3px solid #1EF97C;
}

.produtos-ica-mobile {
  display: none;
}

.produtos-ica h1 {
  text-align: center;
  font-size: 64px;
  font-weight: 700;
  margin-top: 50px;
}

.button-redirect {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: 600;
  width: 286px;
  height: 58px;
  text-decoration: none;
  border: 3px solid #009FFD;
  font-size: 14px;
}

.containt-productIca {
  display: flex;
  margin: 120px 0;
}

.descpription-productIca {
  width: 40%;
  margin-top: 70px;
  margin-left: 30px;
}

.descpription-productIca img {
  margin-bottom: 35px;
  margin-left: 15px;
}

yellow {
  color: #F4A500;
  font-weight: bold;
}

purple {
  color: #540D6E;
  font-weight: bold;
}

light {
  color: #B1D796;
  font-weight: bold;
}

.containt-productIca p {
  width: 80%;
  height: 180px;
  margin-left: 10px;
  font-size: 16px;
  margin-bottom: 30px;
}

.image-icon-products {
  width: 65%;
}

.image-person-product {
  width: 75%;
}

@media screen and (max-width: 1400px) {
  .produtos-ica {
    width: 90%;
    margin: auto;
  }

  .produtos-ica h1 {
    font-size: 40px;
    margin-top: 50px;
  }
  
  .containt-productIca {
    display: flex;
    margin: 80px 0;
  }
  
  .descpription-productIca {
    width: 40%;
    margin-top: 30px;
    margin-left: 80px;
  }
  
  .descpription-productIca img {
    margin-bottom: 35px;
    margin-left: 15px;
  }
  
  .descpription-productIca p {
    width: 70%;
    height: 110px;
    margin-left: 10px;
    font-size: 13px;
    margin-bottom: 30px;
  }
  
  .image-icon-products {
    width: 60%;
  }
  
  .image-person-product {
    width: 65%;
    height: 400px;
  }

  .descpription-productIca a {
    font-size: 11px !important;
    width: 220px !important;
    height: 50px !important;
  }
}


@media screen and (max-width: 600px) {
  .produtos-ica {
    display: none;
  }

  .produtos-ica-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .produtos-ica-mobile div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .produtos-ica-mobile h1 {
    font-size: 30px;
    width: 80%;
    text-align: center;
    margin: auto;
  }

  .Image {
    margin-left: 40%;
    padding-right: 20px;
    margin-top: 50px;
  }

  .Icon {
    width: 25%;
    margin: 40px auto;
  }

  .produtos-ica-mobile p {
    text-align: center;
    width: 70%;
    margin: auto;
  }

  .produtos-ica-mobile a {
    margin: 30px auto;
  }
}