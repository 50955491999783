.description-ecommerce li {
  width: 20%;
  margin: 20px;
}

.description-ecommerce div img {
  padding-top: 10px;
}

.description-ecommerce div {
  display: flex;
}

.description-ecommerce h2 {
  margin-left: 15px;
  height: 10px;
  font-size: 16px;
}

.description-ecommerce p {
  margin-left: 38px;
  font-size: 13px;
  margin-top: 25px;
}

@media screen and (max-width: 1400px) {
  .description-ecommerce li {
    width: 20%;
    margin: 20px;
  }
  
  .description-ecommerce div img {
    padding-top: 10px;
  }
  
  .description-ecommerce div {
    display: flex;
  }
  
  .description-ecommerce h2 {
    margin-left: 15px;
    height: 35px;
    font-size: 15px;
    font-weight: 00;
  }
  
  .description-ecommerce p {
    margin-left: 38px;
    font-size: 11px;
    margin-top: 15px;
  }
}