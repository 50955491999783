.ecommerce {
  background-color: #1EF97C;
  margin-top: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}

.ecommerce-mobile {
  display: none;
}

.container-ecommerce {
  width: 70%;
  margin: auto;
}

.image-computer {
  width: 400px;
  position: relative;
  left: 50%;
  transform: translate(-50%, -45%);
}

.description-ecommerce {
  margin-top: -100px;
}

.description-ecommerce h1 {
  font-size: 20px;
  margin-left: 100px;
  margin-bottom: 60px;
  width: 35%;
}

.description-ecommerce ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

@media screen and (max-width: 1440px) {
  .ecommerce {
    margin-top:150px;
  }

  .image-computer {
    width: 300px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -45%);
  }
  
  .description-ecommerce {
    margin-top: -100px;
  }
  
  .description-ecommerce h1 {
    font-size: 20px;
    width: 40%;
    margin-bottom: 30px;
  }

}


@media screen and (max-width: 600px) {
  .ecommerce {
    display: none;
  }

  .ecommerce-mobile {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
  }

  .image {
    text-align: center;
    position: relative;
    top: -80px;
  }

  .description-ecommerce-mobile {
    margin-top: -50px;
  }

  .container-mobile {
    background-color: black;
  }

  .container-mobile h1 {
    color: white;
    width: 60%;
    text-align: center;
    margin: auto;
  }

  .description-ecommerce-mobile ul {
    list-style: none;
    margin: 40px 0;
  }

  .description-ecommerce-mobile li {
    display: flex;
    color: white;
    margin-left: 40px;
    margin-bottom: 40px;

  }

  .list-header {
    display: flex;
    margin-bottom: 15px;
  }

  .list-header h2 {
    font-size: 16px;
    margin: 0 15px;
  }

  .list-header img {
    
  }

  .espaço {
    width: 55%;
    text-align: center;
  }
  .espaço img {
    margin: 15% auto 0 0;
  }

  .list-item-2 {
    text-align: end;
  }

  .list-item-mobile {
    width: 70%;
  }

  .list-item-mobile p {
    width: 70%;
  }


}