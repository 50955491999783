.maquininha-empresas {
  margin-right: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  height: 530px;
  margin-bottom: 120px;
}

.maquininha-empresas-mobile {
  display: none;
}

.maquininha-empresas img {
  position: absolute;
  right: 0;
  top: -30px;
  width: 1000px;
  z-index: -1;
}

.maquininha-empresas a {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  margin-left: -30px;
  background-color: #1EF97C;

  color: #000;
  text-decoration: none;
  font-weight: bold;

  width: 250px;
  height: 56px;
  font-size: 13px;
}

.maquininha-text {
  width: 23%;
  margin-left: 320px;
  margin-top: 120px;
}

.maquininha-text h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.maquininha-text p {
  font-size: 13px;
  width: 90%;

}

@media screen and (max-width: 1440px) {

  .maquininha-empresas {
    height: 520px;
  }

  .maquininha-empresas img {
    top: -20px;
    width: 1000px;
  }
  
  .maquininha-empresas a {
    margin-top: 30px auto;
    margin-left: -15px;
  }
  
  .maquininha-text {
    width: 25%;
    margin-left: 130px;
    margin-top: 120px;
  }

  .maquininha-text h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .maquininha-text p {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .maquininha-empresas {
    display: none;
  }
  
  .maquininha-empresas-mobile {
    width: 100%;
    display: initial;
  }

  
  
  .maquininha-desc {
    margin: 40px auto;
    width: 70%;
  }

  .maquininha-empresas-mobile a {
    display: flex;
    margin: 50px auto;
    justify-content: center;
    align-items: center;
    background-color: #1EF97C;
  
    color: #000;
    text-decoration: none;
    font-weight: bold;
  
    width: 80%;
    height: 56px;
    font-size: 16px;
  }

  .description-maquininha {
    background-color: #000;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
  }

  .description-maquininha p {
    color: white;
    width: 40%;
  }

  .bar {
    width: 90%;
    height: 30px;
    background-color:#1EF97C;
  }
}