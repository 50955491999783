.benefices-sell {
  display: flex;
  position: relative;
  margin-bottom: 50px;
}

.benefices-sell-mobile {
  display: none;
}

.image-pay-card {
  width: 60%;
}

.description-pay-card {
  margin: 20px 0 0 30px;
}

.description-pay-card h1 {
  font-size: 20px;
  margin-bottom: 40px;
}

.description-pay-card ul {
  list-style: none;
  width: 50%;
  margin-left: 30px;
}

.description-pay-card li {
  display: flex;
  font-size: 13px;
  margin-bottom: 25px;
}

.description-pay-card li p {
  margin-left: 10px;
}

.consult {
  font-size: 10px;
  margin: 0 0 0 40px ;
}

@media screen and (max-width: 1440px) {

  .benefices-sell {
    margin-bottom: 50px;
  }
  
  .description-pay-card {
    margin: 20px 0 0 50px;
  }
  
  .description-pay-card h1 {
    font-size: 18px;
    margin-bottom: 25px;
  }
  
  .description-pay-card ul {
    list-style: none;
    width: 50%;
    margin-left: 30px;
  }
  
  .description-pay-card li {
    display: flex;
    font-size: 13px;
    margin-bottom: 18px;
  }
  
  .description-pay-card li p {
    margin-left: 10px;
  }
  
  .consult {
    font-size: 9px;
    margin-left: 30px ;
  }
}

@media screen and (max-width: 600px) {
  .benefices-sell {
    display: none;
  }

  .benefices-sell-mobile {
    display: initial;
  }

  .pay {
    display: flex;
    align-items: center;
  }

  .pay img {
    width: 50%;
  }

  .pay h1 {
    width: 50%;
    text-align: center;
    font-size: 30px;
  }

  .benefices-sell-mobile h5 {
    margin-left: 30px;
  }

  .benefices-sell-mobile ul {
    margin-left: 50px;
    list-style-image: url("../../../../images/icons/item-description-benefits.svg");
  }

  .benefices-sell-mobile li {
    margin: 15px 0;
    width: 50%;
  }
}