.footer {
  background-color: #323232;
  padding-left: 10%;
  color: #fff;
  display: flex;
  padding-bottom: 20px;
}

.footer-mobile {
  display: none;
}

.informations {
  width: 70%;
}

.informations-footer {
  display: flex;
  padding: 30px 30px 20px 30px;
  width: 100%;
}

.links {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.links a {
  text-decoration: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 5px;
}

.icons-pages {
  margin-top: 50px;
  margin-right: 20px;
  display: flex;
}

.icons-pages a {
  margin: 0 5px;
  height: 50px;
}

.IconsDownload {
  display: flex;
  flex-direction: column;
}

.IconsDownload a {
  height: 40px;
  cursor: pointer;
}

.line {
  width: 845px;
  height: 1px;
  background-color: #1EF97C;
}

.footer h3 {
  width: 105%;
  font-size: 14px;
  margin-bottom: 10px;
}

.address {
  width: 90%;
  font-size: 14px !important;
  margin-bottom: 10px;
}

.footer p {
  font-size: 11px;
}

.directs {
  margin-top: 20px;
  font-size: 10px;
}

@media screen and (max-width: 1400px) {
  
  .footer h3 {
    font-size: 13px;
  }
  
  .address {
    font-size: 12px !important;
  }
  
  .footer p {
    font-size: 10px;
  }
  
  .directs {
    font-size: 9px;
  }
} 


@media screen and (max-width: 600px) {
  .footer {
    display: none;
  }

  green {
    color: #1EF97C;
  }

  .links {
    margin-top: 10px;
  }
  
  .links a {
    text-decoration: none;
    color: white;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .link-p {
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .footer-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: black;
  }

  .footer-mobile div{
    width: 80%;
    margin: auto;
  }

  .footer-mobile p {
    color: white;
  }

  .footer-mobile h2 {
    color: white;
  }

  .footer-sac-mobile {
    padding-bottom: 30px;
  }

  .footer-sac-mobile p {
    margin: 20px 0;
    font-size: 20px;
  }

  .footer-sac-mobile h5 {
    margin: 10px 0;
  }

  .footer-mobile-infos {
    text-align: center;
    padding-bottom: 50px;
  }

  .footer-mobile-infos h2 {
    font-size: 18px;
    margin: 20px 0;
  }

  .footer-mobile-infos p {
    font-size: 14px;
    font-weight: 300;
    margin: 20px 0;
  }

  .line-mobile {
    width: 80% !important;
    height: 1px;
    margin-top: 50px;
    background-color: #1EF97C;
  }

  .footer-mobile h4 {
    color: white;
    font-weight: 300;
    font-size: 13px;
    margin: 30px 0;
  }
  .download {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
  }

  .download a {
    margin: 0 5px;
  }
}