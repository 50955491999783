.intro-home {
  padding-top: 50px;
}

.title {
  font-size: 35px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 60px;
  line-height: 50px;
  letter-spacing: 3px;
}

.image-cell {
  left: 10px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.descriptions-intro {
  padding-top: 400px;
  display: flex;
  width: 80vw;
  margin: auto;
  justify-content: space-between;
}

.colum-items {
  width: 28%;
}

.item {
  margin-top: 100px
}

.item h2 {
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.item p {
  font-size: 15px;
  font-weight: 400px;
}

.intro-home-mobile {
  display: none;
}

@media screen and (max-width: 1400px) {
  .title {
    font-size: 35px;
    margin-bottom: 70px;
    line-height: 50px;
  }

  .image-cell img {
    width: 500px;
  }

  .descriptions-intro {
    padding-top: 330px;
  }

  .item {
    margin-top: 80px
  }
  
  .item h2 {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 1px;
  }
  
  .item p {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .intro-home {
    display: none;
  }

  .intro-home-mobile {
    display: initial;
  }

  .title-mobile {
    text-align: center;
    font-size: 40px;
    width: 90%;
    margin: auto;
  }

  .image-cell-mobile {
    text-align: center;
    margin-top: 20px;
  }

  .image-cell-mobile img {
    width: 80%;
  }

  .description-mobile-1 {
    background-color: black;
    text-align: center;
    padding: 50px 0;
    margin-top: 20px;
  }

  .description-mobile-1 h1 {
    color: #1EF97C;
    font-size: 40px;
    margin: 0 auto 30px auto; 
    width: 90%;
  }

  .description-mobile-1 p {
    color: white;
    width: 80%;
    font-size: 25px;
    margin: auto;
  }

  .description-mobile-2 {
    text-align: center;
    padding: 50px 0;
    margin-top: 20px;
  }

  .description-mobile-2 h1 {
    color: #1EF97C;
    font-size: 40px;
    width: 90%;
    margin: 0 auto 30px auto; 
  }

  .description-mobile-2 p {
    width: 80%;
    font-size: 25px;
    margin: auto;
  }

}