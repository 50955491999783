.benefices-funcionaries {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.benefices-funcionaries-mobile {
  display: none;
}

.description-benefices-funcionaries {
  width: 26%;
}

.description-benefices-funcionaries h1 {
  font-size: 20px;
}

.description-benefices-funcionaries p {
  margin: 10px 0;
  font-size: 14px;
}

.description-benefices-funcionaries ul {
  list-style: none;
  margin-left: 20px;
}

.description-benefices-funcionaries li {
  display:flex;
  align-items: center;
  width: 60%;
}

.description-benefices-funcionaries li p {
  margin-bottom: 20px;
  margin-left: 15px;
  font-size: 13px;
}

.image-benefices-funcionaries {
  margin-top: 50px;
  width: 700px;
}

.description-benefices-funcionaries-h4 {
  font-weight: normal;
  font-size: 12px;
}

@media screen and (max-width: 1440px) {
  .benefices-funcionaries {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  
  .description-benefices-funcionaries {
    width: 30%;
  }
  
  .description-benefices-funcionaries h1 {
    font-size: 18px;
    width: 95%;
  }
  
  .description-benefices-funcionaries p {
    margin: 10px 0;
    font-size: 13px;
  }

  .description-benefices-funcionaries ul {
    margin-left: 20px;
  }
  
  .description-benefices-funcionaries li {
    display:flex;
    align-items: center;
    width: 60%;
  }
  
  .description-benefices-funcionaries li p {
    margin-bottom: 10px;
    margin-left: 15px;
  }
  
  .image-benefices-funcionaries {
    margin-top: 50px;
    width: 550px;
  }
}

@media screen and (max-width: 600px) {
  .benefices-funcionaries {
    display: none;
  }

  .benefices-funcionaries-mobile {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .image-benefices-funcionaries-mobile {
    position: relative;
    margin-top: 50px;
    right: 0;
  }

  .image-benefices-funcionaries-mobile img {
    width: 70%;
    margin: 0 0 0 30%;
  }

  .description-benefices-funcionaries-mobile {
    width: 80%;
    margin: auto;
  }

  green {
    color: #1EF97C;
  }
  .description-benefices-funcionaries-mobile h1 {
    width: 60%;
  }

  .description-benefices-funcionaries-mobile ul {
    list-style: none;
  }

  .description-benefices-funcionaries-mobile li {
    display: flex;
    margin: 20px 0;
    width: 45%;
  }

  .description-benefices-funcionaries-mobile li p{
    margin-left: 15px;
  }
  
}