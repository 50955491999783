.input-animated {
  width: 400px;
  padding: 15px 0;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.input-data {
  height:40px;
  width: 100%;
  position: relative;
}

.input-data input {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid silver;
  color: rgb(78, 78, 78); 
}

.input-data input:focus ~ label,
.input-data input:valid ~ label {
  transform: translateY(-25px);
  font-size: 18px;
  color: black  ;
}

.input-data label {
  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
  transition: all 0.3s ease;
}

.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.underline:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: black;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before {
  transform: scaleX(1);
}

@media screen and (max-width: 1400px) {
  .input-animated {
    width: 350px;
    padding: 10px 0;
  }

  .input-data input {
    font-size: 14px;
  }

  .input-data input:focus ~ label,
.input-data input:valid ~ label {
  font-size: 15px;
}

.input-data label {
  font-size: 13px;
}
}