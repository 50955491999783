.cartao {
  position: relative;
}

.image-card {
  position: absolute;
  right: 0;
  z-index: -1;
  width: 93%;
}

.desc-card {
  position: relative;
  width: 70%;
  padding-top: 30px;
  padding-left: 200px;
}

.desc-card-min {
  padding-left: 150px;
  width: 50% !important; 
  margin: 20px 0;
}

.desc-card h1 {
  font-size: 20px;
  font-weight: 700;
}

.desc-card p {
  font-size: 13px;
  width: 30%;
}

.button {
  margin-top: 30px;
  margin-left: 120px;
}

.button a {
  padding: 20px 66px;
  background-color: white;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 13px;
}

.cartao-mobile {
  display: none;
}

@media screen and (max-width: 1440px) {
  
  .desc-card {
    margin-top: 10px;
    width: 75%;
  }

  .desc-card h1 {
    font-size: 21px;
  }

  .desc-card p {
    font-size: 12px;
    width: 30%;
  }

  .desc-card-min {
    padding-left: 100px;
    width: 100%; 
  }

  .button {
    margin-top: 50px;
    margin-left: 120px;
  }

  .button a {
    padding: 20px 50px;
  }
}

@media screen and (max-width: 600px) {
  .cartao {
    display: none;
  }

  .cartao-mobile {
    display: initial;
  }

  .cartao-mobile img {
    width: 100%;
    margin-bottom: 100px;
  }
}