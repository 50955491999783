.beneficios {
  margin: 120px 80px 80px 50px;
  display: flex;
  align-items: center;
}

green {
  color: #1EF97C;
}

.description-benefits {
  margin-left: 50px;
}

.description-benefits h1 {
  margin-bottom: 20px;
}

.description-benefits ul {
  list-style-image: url("../../../../images/icons/item-description-benefits.svg");
  display: flex;
  flex-wrap: wrap;
}

.description-benefits ul span {
  width: 40%;
  padding: 18px 30px;
}

.description-benefits ul span h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.description-benefits span p {
  font-size: 13px
}

.description-benefits a {
  margin-top: 30px; 
  width: 286px;
  height: 56px;
  font-size: 14px !important;
}

.beneficios-mobile {
  display: none;
}

@media screen and (max-width: 1440px) {
  .beneficios {
    margin: 120px 80px 80px 50px;
  }

  .beneficios img {
    width: 550px;
  }

  .description-benefits ul span {
    width: 50%;
    padding: 15px 30px;
  }

  .description-benefits ul span h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .description-benefits span p {
    font-size: 13px
  }

  .description-benefits a {
    margin-top: 30px; 
    width: 286px;
    height: 56px;
    font-size: 14px !important;
  }

}

@media screen and (max-width: 600px) {
  .beneficios {
    display: none;
  }

  .beneficios-mobile {
    display: initial;
    width: 100%;
  }

  .beneficios-mobile h1 {
    text-align: center;
    font-size: 35px;
    width: 90%;
    margin: 20px auto 70px auto;
  }

  .description-beneficios-mobile ul {
    padding: 50px 0;
    list-style: none;
    background-color: black;
    width: 95%;
    margin: auto;
  }

  .description-beneficios-mobile li {
    padding: 20px 15px;
  }
  .description-beneficios-mobile li h2 {
    color: #1EF97C;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-left: 34px;
  }

  .description-beneficios-mobile-btn {
    width: 80%;
    margin: 100px auto;
    padding: 30px;
    text-align: center;
    border: 5px solid #1EF97C;
    font-weight: bold;
    font-size: 20px;
  }

  .icon-description-mobile {
    display: flex;
  }

  .icon-description-mobile img {
    height: 15px;
    margin-top: 10px;
  }

  .description-beneficios-mobile li p {
    color: white;
    margin-left: 15px;
    font-size: 22px;
  }
}
