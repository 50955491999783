.pix {
  width: 100%;
  margin-top: 80px;
}

.pix-content {
  display: flex;
  position: relative;
}

.pix-texts {
  margin-left: 180px;
}

.pix-person {
  position: absolute;
  right: 0;
}

.pix-item {
  position: absolute;
  top: 55px;
  width: 25%;
  left: 250px;
}

.pix-item h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.pix-item p {
  width: 70%;
  font-size: 13px;
}

.pix-item2 {
  position: absolute;
  top: 220px;
  width: 25%;
  left: 250px;
}

.pix-item2 h1 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pix-texts img {
  width: 470px;
}

.pix-item2 p {
  width: 57%;
  font-size: 13px;
}

.pix-person {
  position: absolute;
  right: 0;
  width: 800px;
}

.pix a {
  margin: 100px auto 0 auto;
}

.pix-mobile {
  display: none;
}

@media screen and (max-width: 1440px) {
  .pix-texts {
    margin-left: 130px;
  }

  .pix-person {
    width: 700px;
  }

  .pix-item {
    position: absolute;
    top: 50px;
    width: 27%;
    left: 200px;
  }

  .pix-item h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .pix-item p {
    width: 70%;
    font-size: 13px;
  }

  .pix-item2 {
    position: absolute;
    top: 210px;
    width: 25%;
    left: 200px;
  }

  .pix-item2 h1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .pix-item2 p {
    width: 57%;
    font-size: 12px;
  }

  .pix a {
    margin: 20px auto 0 auto;
    width: 286px;
    height: 56px;
    font-size: 14px;
  }

}

@media screen and (max-width: 600px) {
  .pix {
    display: none;
  }

  green {
    color: #1EF97C;
  }

  .pix-mobile {
    display: initial;
    width: 90%;
    text-align: center;
    margin: auto;
  }

  .pix-mobile h1 {
    text-align: center;
    width: 70%;
    font-size: 30px;
    margin: 50px auto 0 auto;
  }

  .image-pix-mobile {
    width: 80%;
    margin-top: 30px;
  }

  .pix-mobile h3 {
    margin: 50px;
  }

  .button-pix-mobile {
    border: 4px solid #1EF97C;
    width: 80%;
    margin: auto;
    padding: 10px 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }

  .logo-pix {
    margin: 50px 0;
  }
}